// Layout
const dashboardLayout = () => import('@/layouts/CompanyLayout/DashboardLayout/dashboardLayout.vue')

const drivers = () => import('./views/index')
const addDriver = () => import('./views/addDriver')

// start Routes
export default [
  {
    path: '/company/drivers',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'companyDrivers',
        meta: {
          name: 'companyDrivers',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: drivers
      },
      {
        path: 'add-driver',
        name: 'companyAddDriver',
        meta: {
          name: 'companyAddDriver',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: addDriver
      },
      {
        path: 'show-driver/:id',
        name: 'compmanyShowDriver',
        props: true,
        meta: {
          name: 'compmanyShowDriver',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: addDriver
      },
      {
        path: 'edit-driver/:id',
        name: 'companyEditDriver',
        props: true,
        meta: {
          name: 'companyEditDriver',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: addDriver
      }
    ]
  }
]
