export default {
  loginInfo: 'تسجيل دخول السائقين و الشركات',
  IdNumber: 'رقم الهوية',
  password: 'الرقم السرى',
  phoneNumber: 'رقم الجوال',
  secondPhone: 'رقم جوال اخر',
  userName: 'اسم المستخدم',
  fullName: 'الاسم بالكامل',
  email: 'البريد الالكترونى',
  address: 'العنوان',
  otbCode: 'الرمز',
  check: 'تحقق',
  city: 'المدينه',
  enter: 'دخول',
  createNewAccount: ' تسجيل حساب جديد',
  createAccount: ' تسجيل حساب',
  codeOtb: 'كود الرمز',
  otbTitle: 'تم إرسال رسالة إلى رقم الجوال الخاص بكم',
  otbInfo: 'تتضمن رمز OTB',
  continueOtb: 'المتابعة',
  create: 'تسجيل',
  enterLogin: 'تسجيل الدخول',
  companyName: 'اسم الشركة',
  images: 'الصور',
  countryCode: 'كود الدولة',
  companyInfo: 'معلومات الشركة',
  continueInfo: 'تكملة البيانات',
  publish: 'النشر',
  save: 'حفظ'
}
