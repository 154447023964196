import axios from 'axios'
import { core } from '@/config/pluginInit'
import router from './router'
import { VUE_APP_SSO_LINK, VUE_APP_TRANSPORTATION_LINK, VUE_APP_WEBSITE_LINK } from '@/config/constant'
export const API_HEADERS = {
  Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`,
  Accept: 'application/json',
  ContentType: 'application/json',
  'Accept-Language': 'ar',
  'Content-Language': 'ar'
}

export const apiResponseCatch = (error) => {
  console.log(error.response)
  if (error?.response?.data?.errors && Object.keys(error.response.data.errors).length) {
    // eslint-disable-next-line no-prototype-builtins
    if (error.response.data.errors?.hasOwnProperty('translations')) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors.translations)) {
        core.showSnackbar('error', value)
      }
    } else {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        core.showSnackbar('error', value)
      }
    }
  } else if (error?.response?.data) {
    core.showSnackbar('error', error.response.data.message)
  }
  return Promise.reject(error)
}

export function singleSignOn () {
  const apiClient = axios.create({
    baseURL: VUE_APP_SSO_LINK,
    headers: API_HEADERS
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, apiResponseCatch)
  return apiClient
}
export function website () {
  console.log('website')
  console.log(JSON.parse(localStorage.getItem('userToken')))
  const apiClient = axios.create({
    baseURL: VUE_APP_WEBSITE_LINK,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`,
      Accept: 'application/json',
      ContentType: 'application/json',
      'Accept-Language': 'ar',
      'Content-Language': 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.data) {
      if (error.response.data.message === 'Authorization Error' || error.response.status === 401) {
        router.push({ name: 'login' })
      } else {
        // console.log('error.response => ', error.response.data.message)
        core.showSnackbar('error', error.response.data.message)
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      }
    }
    return Promise.reject(error)
  })
  return apiClient
}

export function transportation () {
  const apiClient = axios.create({
    baseURL: VUE_APP_TRANSPORTATION_LINK,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('userToken'))}`,
      Accept: 'application/json',
      ContentType: 'application/json',
      'Accept-Language': 'ar',
      'Content-Language': 'ar'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    console.log({ error })
    if (error.response.data) {
      if (error.response.data.message === 'Authorization Error' || error.response.status === 401) {
        router.push({ name: 'login' })
      } else {
        // console.log('error.response => ', error.response.data.message)
        core.showSnackbar('error', error.response.data.message)
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      }
    }
    return Promise.reject(error)
  })
  return apiClient
}
