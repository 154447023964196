import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_MESSAGING_SENDER_ID, FIREBASE_MEASUREMENT_ID, FIREBASE_APP_ID } from '@/config/constant'

// import { getMessaging } from 'firebase/messaging'
//
const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
}

console.log({ firebaseConfig })
//
// // Initialize Firebase
// const app = initializeApp(firebaseConfig)

// const analytics = getAnalytics(app)
firebase.initializeApp(firebaseConfig)
// firebase.messaging().usePublicVapidKey('BFbo_eh8ZBfTTsBYAMgeFQ-yWGMdDZit0eKkEo9ObVF63f131bzl5rPrc4KUynEeGHzTF_Bm6_-Hp88vQwotglY ')
export default firebase.messaging()
