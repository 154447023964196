/* eslint-disable eqeqeq */
export default {
  methods: {
    hasPer: (nameOfRole) => {
      return JSON.parse(localStorage.getItem('my-roles')) && JSON.parse(localStorage.getItem('my-roles')).indexOf(nameOfRole) > -1
    },
    isType: () => true,
    isAdmin: () => true
  }
}
