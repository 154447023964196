// Layout
const dashboardLayout = () => import('@/layouts/CompanyLayout/DashboardLayout/dashboardLayout.vue')

const vehicles = () => import('./views/index')
const addVehicles = () => import('./views/addVehicle')

// start Routes
export default [
  {
    path: '/company/vehicles',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'companyVehicles',
        meta: {
          name: 'companyVehicles',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: vehicles
      },
      {
        path: 'add-vehicles',
        name: 'companyAddVehicles',
        meta: {
          name: 'companyAddVehicles',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: addVehicles
      }
    ]
  }
]
