import admins from './admins/admins'
import main from './main/main'
import auth from './auth/auth'
import trips from './trips/trips'
import drivers from './drivers/drivers'
export default {
  admins,
  main,
  auth,
  trips,
  drivers
}
