import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/modules/auth/auth.routes'
import authMixin from '@/mixins/auth-permission.js'
// company routes **********
import companyDashboardRoutes from '@/modules/Company/dashboard/dashboard.routes'
import companySettingsRoutes from '@/modules/Company/settings/settings.routes'
import companyTripsRoutes from '@/modules/Company/trips/trips.routes'
import companyWithdrawProcess from '@/modules/Company/withdrawProcess/withdrawProcess.routes.'
import companyDriversRoutes from '@/modules/Company/drivers/drivers.routes'
import companyVehiclesRoutes from '@/modules/Company/vehicles/vehicles.routes'
import companyDriver from '@/modules/Company/support/support.routes'

// driver routes **********
import driverDashboardRoutes from '@/modules/Driver/dashboard/dashboard.routes'
import driverSettingsRoutes from '@/modules/Driver/settings/settings.routes'
import driverTripsRoutes from '@/modules/Driver/trips/trips.routes'
import driverVehiclesRoutes from '@/modules/Driver/vehicles/vehicles.routes'
import driverWithdrawProcess from '@/modules/Driver/withdrawProcess/withdrawProcess.routes.'
import driverProcessingRoutes from '@/modules/Driver/processing/processing.routes'
import supportDriver from '@/modules/Driver/support/support.routes'
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

Vue.use(VueRouter)

const routes = [
  ...auth,
  // company ******
  ...companyDashboardRoutes,
  ...companySettingsRoutes,
  ...companyTripsRoutes,
  ...companyWithdrawProcess,
  ...companyDriversRoutes,
  ...companyVehiclesRoutes,
  ...companyDriver,

  // driver ******
  ...driverDashboardRoutes,
  ...driverSettingsRoutes,
  ...driverTripsRoutes,
  ...driverVehiclesRoutes,
  ...driverWithdrawProcess,
  ...driverProcessingRoutes,
  ...supportDriver,
  {
    path: '*',
    name: 'errorPage',
    meta: { name: 'dashboard', userType: 'both' },
    component: ErrorPage
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  console.log('Current Route Role:', to)
  console.log('Has Permission:', authMixin.methods.hasPer(to.meta.role))
  if (localStorage.getItem('my-roles') && authMixin.methods.hasPer(to.meta.role)) {
    console.log('router permisson')
    next()
  } else {
    if (to.meta.role === 'both') {
      console.log('router next')
      next()
    } else {
      console.log('login')
      next({ name: 'login' })
    }
  }
})
// router.beforeEach((to, from, next) => {
//   console.log('Hello')
//   next(vm => {
//     console.log('vm.checkIfLogin() => ', vm.checkIfLogin())
//     if (!vm.checkIfLogin()) {
//       vm.logout()
//     } else next()
//   })
// })
/* router.beforeEach((to, from, next) => {
    next(vm => {
      if (!vm.checkIfLogin()) {
        next({ name: 'login' })
      } else next()
    })
  if (to.meta.userType === 'both') {
    next()
  } else if (securityFunctions.methods.isAdmin(to.meta.userType)) {
    if (securityFunctions.methods.hasPer(to.meta.permission)) {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  } else {
    if (securityFunctions.methods.hasServiceType(to.meta.serviceTypes) || to.meta.serviceTypes === 'all') {
      next()
    } else {
      debugger
      next({ name: 'errorPage' })
    }
  }
}) */
export default router
