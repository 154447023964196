// Layout
const dashboardLayout = () => import('@/layouts/DriverLayout/DashboardLayout/dashboardLayout.vue')

const vehicles = () => import('./views/index')
const driverAddVehicles = () => import('./views/addVehicle')

// start Routes
export default [
  {
    path: '/driver/vehicles',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'driverVehicles',
        meta: {
          name: 'driverVehicles',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.driver'
        },
        component: vehicles
      },
      {
        path: '/add-vehicles',
        name: 'driverAddVehicles',
        meta: {
          name: 'driverAddVehicles',
          parentName: 'transportation',
          userType: '',
          permission: '',
          role: 'transportation.driver'
        },
        component: driverAddVehicles
      }
    ]
  }
]
