export default {
  trips: 'trips',
  tripId: 'id',
  pickUp: 'pickUp point',
  pickIn: 'pickIn point',
  distance: 'distance',
  capacityNumber: 'capacity number',
  tripPrice: 'trip price',
  date: 'date',
  status: 'status',
  control: 'controls',
  withdrawProcess: 'payment way',
  withdrawDate: 'withdraw date',
  price: 'price'
}
