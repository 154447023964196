// Layout
const dashboardLayout = () => import('@/layouts/CompanyLayout/DashboardLayout/dashboardLayout.vue')

const support = () => import('./views/index')

// start Routes
export default [
  {
    path: '/company/support',
    component: dashboardLayout,
    children: [
      {
        path: '',
        name: 'companySupport',
        meta: {
          name: 'companySupport',
          parentName: 'marketplace',
          userType: '',
          permission: '',
          role: 'transportation.company_owner'
        },
        component: support
      }
    ]
  }
]
