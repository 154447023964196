export default {
  loginInfo: 'Log in drivers and companies',
  IdNumber: 'id number',
  password: 'password',
  phoneNumber: 'phone number',
  secondPhone: 'second phone',
  userName: 'user name',
  fullName: 'full name',
  email: 'E-mail',
  address: 'address',
  otbCode: 'OTB Code',
  check: 'check',
  city: 'city',
  enter: 'enter',
  createNewAccount: 'create new account',
  createAccount: ' login',
  codeOtb: 'code OTB',
  otbTitle: 'تم إرسال رسالة إلى رقم الجوال الخاص بكم',
  otbInfo: 'تتضمن رمز OTB',
  continueOtb: 'continue',
  create: 'create',
  enterLogin: 'login',
  companyName: 'company name',
  images: 'images',
  countryCode: 'country code',
  companyInfo: 'company information',
  continueInfo: 'continue information',
  publish: 'publish',
  save: 'save'
}
