export default {
  drivers: 'drivers',
  driverName: 'driver name',
  phoneNumber: 'phone number',
  tripsCount: 'trips count',
  imports: 'imports',
  paymentWay: 'payment way',
  lastTrip: 'last trip',
  control: 'controls',
  addDriver: 'add driver',
  vehicles: 'vehicles'
}
