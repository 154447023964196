export const VUE_APP_SSO_LINK = process.env.VUE_APP_SSO_LINK || 'http://172.16.40.121:8000'
export const VUE_APP_TRANSPORTATION_LINK = process.env.VUE_APP_TRANSPORTATION_LINK || 'http://172.16.40.121:3009/api'
export const VUE_APP_WEBSITE_LINK = process.env.VUE_APP_WEBSITE_LINK || 'http://172.16.40.121:3006/api'
export const FIREBASE_API_KEY = process.env.VUE_FIREBASE_API_KEY || 'AIzaSyCOjq1ZfAuB3sF9bA5M7i3fbKA0oJxTSBg'
export const FIREBASE_AUTH_DOMAIN = process.env.VUE_FIREBASE_AUTH_DOMAIN || 'ibbil-6e726.firebaseapp.com'
export const FIREBASE_PROJECT_ID = process.env.VUE_FIREBASE_PROJECT_ID || 'ibbil-6e726'
export const FIREBASE_STORAGE_BUCKET = process.env.VUE_FIREBASE_STORAGE_BUCKET || 'ibbil-6e726.appspot.com'
export const FIREBASE_MESSAGING_SENDER_ID = process.env.VUE_FIREBASE_MESSAGING_SENDER_ID || '846950817960'
export const FIREBASE_APP_ID = process.env.VUE_FIREBASE_APP_ID || '1:846950817960:web:d4acbd5056630d8c09a29d'
export const FIREBASE_MEASUREMENT_ID = process.env.VUE_FIREBASE_MEASUREMENT_ID || 'G-MCBGKVMLEL'
