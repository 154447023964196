// Layout
const dashboardLayout = () => import('@/layouts/CompanyLayout/DashboardLayout/dashboardLayout.vue')

const withdrawProcess = () => import('./views/index')

// start Routes
export default [
  {
    path: '/company/withdrawProcess',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'companyWithdrawProcess',
      meta: {
        name: 'companyWithdrawProcess',
        parentName: 'transportation',
        userType: '',
        permission: '',
        role: 'transportation.company_owner'
      },
      component: withdrawProcess
    }
    ]
  }
]
