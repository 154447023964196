import admins from './admins/admins'
import main from './main/main'
import auth from './auth/auth'
import drivers from './drivers/drivers'
import trips from './trips/trips'
export default {
  admins,
  main,
  auth,
  drivers,
  trips
}
