import axios from 'axios'
import { API_HEADERS, apiResponseCatch, transportation, website } from '@/axios'
export default {
  listDataTabl (url, filter) {
    return transportation().get(url, {
      params: filter
    })
  },
  commonUploadFiles (payload, config) {
    return website().post('upload', payload, config)
  },
  commonUploadImages (payload, config) {
    return website().post('upload', payload, config)
  },
  removeImage (id, type) {
    return transportation().get(`remove-image/${id}/${type}`)
  },
  removeRow (url, rowId) {
    console.log(url, rowId)
    return transportation().delete(`${url}/${rowId}`)
  },
  changeStatus (baseURL = '', url, id) {
    console.log(baseURL, url, id)
    const apiClient = axios.create({
      baseURL: baseURL,
      headers: API_HEADERS
    })
    apiClient.interceptors.response.use((response) => {
      return response
    }, apiResponseCatch)
    return apiClient.patch(`${url}/${id}`)
  }
}
