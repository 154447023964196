// Layout
const dashboardLayout = () => import('@/layouts/DriverLayout/DashboardLayout/dashboardLayout.vue')

const withdrawProcess = () => import('./views/index')

// start Routes
export default [
  {
    path: '/driver/withdrawProcess',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'driverWithdrawProcess',
      meta: {
        name: 'driverWithdrawProcess',
        parentName: 'withdrawProcess',
        userType: '',
        permission: '',
        role: 'transportation.driver'
      },
      component: withdrawProcess
    }
    ]
  }
]
