// Layout
const dashboardLayout = () => import('@/layouts/DriverLayout/DashboardLayout/dashboardLayout.vue')

const superAdminDashboard = () => import('./views/index')

// start Routes
export default [
  {
    path: '/driver',
    component: dashboardLayout,
    children: [{
      path: '',
      name: 'driverDashboard',
      meta: {
        name: 'driverDashboard',
        userType: '',
        permission: '',
        role: 'transportation.driver'
      },
      component: superAdminDashboard
    }
    ]
  }
]
